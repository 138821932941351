import React from "react";
import { Link } from "gatsby";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./assets/_index.scss";

class Breadcrumbs extends React.Component {
    render() {
        return (
            <section className={`section-breadcrumb${this.props.className}`}>
                <Row>
                    <Col xs={12}>
                        <ul className="list-inline">
                            {this.props.pageArray &&
                                <>
                                    <li className="list-inline-item"><Link to="/" className="breadcrumb-link">Home</Link></li>
                                    {this.props.pageArray.parentSlug &&
                                        <>
                                        <li className={"list-inline-item"}><Link to={`/${this.props.pageArray.parentSlug}/`} className="breadcrumb-link">{this.props.pageArray.parentTitle}</Link></li>
                                        <li className={"list-inline-item"}>{this.props.pageArray.childTitle}</li>
                                        </>
                                    }
                                    {!this.props.pageArray.parentSlug &&
                                        <li className={"list-inline-item"}>{this.props.pageArray.childTitle}</li>
                                    }
                                </>
                            }
                            {!this.props.pageArray &&
                                <>
                                    <li className="list-inline-item"><Link to="/" className="breadcrumb-link">{this.props.pageName}</Link></li>

                                    {this.props.pageLink_1 === "#" && 
                                    <li className={"list-inline-item " + this.props.lastClass_1}>{this.props.pageName_1}</li>
                                    }
                                    {this.props.pageLink_1 !== "#" && 
                                    <li className={"list-inline-item " + this.props.lastClass_1}><Link to={`${this.props.pageLink_1}/`} className="breadcrumb-link">{this.props.pageName_1}</Link></li>
                                    }

                                    {this.props.pageLink_2 === "#" && 
                                    <li className={"list-inline-item " + this.props.lastClass_2}>{this.props.pageName_2}</li>
                                    }
                                    {this.props.pageLink_2 !== "#" && 
                                    <li className={"list-inline-item " + this.props.lastClass_2}><Link to={`${this.props.pageLink_2}/`} className="breadcrumb-link">{this.props.pageName_2}</Link></li>
                                    }
                                    {this.props.pageName_3 &&
                                    <li className={"list-inline-item " + this.props.lastClass_3}>{this.props.pageName_3}</li>
                                    }
                                </>
                            }
                        </ul>
                    </Col>
                </Row>
            </section>
        );
    }
}

Breadcrumbs.defaultProps = {
    pageArray: false
}
export default Breadcrumbs
