import React, { Fragment } from "react";
import { Link } from "gatsby";
import Moment from 'react-moment';

import ArrowIcon from '@icons/arrow.inline.svg';

import SocialShare from '@components/social-share';

const ContentHeaderImageLinks = React.forwardRef((props, ref) => (
    <div className="section-content-header-image__cta" ref={ref}>
        {props.ButtonData.map((btn, index) => {
            if (btn.page) { // if its a normal internal link
                return(
                    <Fragment key={index}>
                        <Link to={`/${btn.page.Slug}/`} className={props.btnClass}>
                            {btn.Label}
                            <ArrowIcon className="icon-arrow-right" />
                        </Link>
                    </Fragment>
                )
            } else if (btn.targetLink) { // using href target link to scroll to ID
                return(
                    <Fragment key={index}>
                        <a href={`${btn.targetLink}/`} className={props.btnClass}>
                            {btn.Label}
                            <ArrowIcon className="icon-arrow-right" />
                        </a>
                    </Fragment>
                )
            } else if (btn.externalLink) { // using external link
                return(
                    <Fragment key={index}>
                        <a href={`${btn.externalLink}/`} className={props.btnClass} target="_blank" rel="noreferrer">
                            {btn.Label}
                            <ArrowIcon className="icon-arrow-right" />
                        </a>
                    </Fragment>
                )
            } else {
                return null
            }
        })}
        {props.showDate && <span className="showDate font-weight-bold"><Moment format="Do MMMM YYYY">{props.publishDate}</Moment></span>}
        {props.showShare && <span className="showDate font-weight-bold"><SocialShare btnLabel="Share this Post" pageLocation={props.pageLocation} pageTitle={props.pageTitle} sharePage={props.sharePage} /></span>}
    </div>
));

export default ContentHeaderImageLinks